.search {
  position: relative;
  z-index: 10;
}

.search-results {
  position: absolute;
  z-index: 20;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}



.modal-dark {
  background-color: rgba(15, 15, 15, 0.8);
}

.days_of_week {
  display: flex;
  flex-direction: row;
}

.jour {
  border-radius: 50%;
  padding: 6px 12px;
  margin-left: 5px;
  cursor: pointer;
}

.jour:hover {
  background-color: rgb(210, 210, 210);
}

.jour.active {
  background-color: rgb(0, 59, 154);
  color: white;
}

.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-top: 5px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
}

.custom-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 4px;
  transition: background-color 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.custom-checkbox:checked ~ .checkmark {
  background-color: #2196F3;
  box-shadow: 0 3px 7px rgba(33, 150, 243, 0.3);
}

.custom-checkbox:checked ~ .checkmark:after {
  display: block;
}

@keyframes checkAnim {
  0% {
    height: 0;
  }

  100% {
    height: 10px;
  }
}

.custom-checkbox:checked ~ .checkmark:after {
  animation: checkAnim 0.2s forwards;
}

.btn-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;
}

.btn-switch {
  background-color: gray;
  width: 78px;
  height: 32px;
  border-radius: 16px;
  position: relative;
  transition: 0.3s;
  cursor: pointer;
}

.btn-switch::before {
  content: "";
  font-weight: 900;
  background-color: white;
  width: 24px;
  height: 24px;
  position: absolute;
  border-radius: 50%;
  top: 4px;
  left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ddd;
  transition: 0.3s;
}

.btn-checkbox:checked + .btn-switch {
  background-color: gray;
}

.btn-checkbox:not(:checked) + .btn-switch {
  background-color: #0075ff;
}

.btn-checkbox:checked+.btn-switch::before {
  content: "";
  left: 50px;
  color: #0075ff;
}

.height600 {
  width: 100%;
  max-width: 2300px;
  margin: 0 auto;
}




.list-event{
  background-color: black;
  color: white;
  width: 100%;
}

.action-event{
  padding: 10px 40px;
  width: 100%;
  cursor: pointer;

}

.action-event:hover{
  background-color: #0075ff;
  cursor: pointer;
}


.hieght600{
  height: 76vh;
  
}





.groupe-check{
    display: flex;
  justify-content: center;
}
.checkindividual {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.checkindividual input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmark {
  position: absolute;
  top:3px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.checkindividual:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkindividual input:checked ~ .checkmark {
  background-color: #2196F3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}


.checkindividual input:checked ~ .checkmark:after {
  display: block;
}


.checkindividual .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
