@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

:root {
  --light: #f6f6f9;
  --primary: #1976d2;
  --light-primary: #cfe8ff;
  --grey: #eeeeee;
  --dark-grey: #aaaaaa;
  --dark: #363949;
  --danger: #d32f2f;
  --light-danger: #fecdd3;
  --warning: #fbc02d;
  --light-warning: #fff2c6;
  --success: #388e3c;
  --light-success: #bbf7d0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.bx {
  font-size: 1.9rem;
}
.bx-cog{
  font-size: 1.99rem;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

html {
  overflow-x: hidden;
}

body.dark {
  --light: #181a1e;
  --grey: #25252c;
  --dark: #fbfbfb;
}

body {
  background: var(--grey);
  overflow-x: hidden;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  background: var(--light);
  width: 230px;
  height: 100%;
  /* z-index: 2000; */
  overflow-x: hidden;
  scrollbar-width: none;
  transition: all 0.3s ease;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar.close {
  width: 60px;
}

.sidebar .logo {
  box-sizing: content-box;
  z-index: 500;
  height: 56px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar .logo-sm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
}
.sidebar .logo-sm img {
  width: 40px;
  margin-left: 10px;
}

.sidebar .side-menu {
  width: 100%;
  margin-top: 48px;
}

.sidebar .side-menu li {
  height: 48px;
  background: transparent;
  border-radius: 48px 0 0 48px;
  padding: 4px;
  margin-left: -20px;
}

.sidebar .side-menu li.actives {
  background: var(--grey);
  position: relative;

}

.sidebar .side-menu li.actives::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: -40px;
  right: 0;
  box-shadow: 20px 20px 0 var(--grey);
  z-index: -1;
  
}

.sidebar .side-menu li.actives::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  bottom: -40px;
  right: 0;
  box-shadow: 20px -20px 0 var(--grey);
  z-index: -1;
  
}

.sidebar .side-menu li .link {
  width: 100%;
  height: 100%;
  background: var(--light);
  display: flex;
  align-items: center;
  border-radius: 48px;
  font-size: 16px;
  color: var(--dark);
  white-space: nowrap;
  overflow-x: hidden;
  transition: all 0.3s ease;
}

.sidebar .side-menu li.actives .link {
  color: var(--primary);
}

.sidebar.close .side-menu li .link {
  width: calc(48px - (4px * 2));
  transition: all 0.3s ease;
}

.sidebar .side-menu li .link .bx {
  min-width: calc(60px - ((4px + 6px) * 2));
  display: flex;
  font-size: 1.6rem;
  justify-content: center;
}

.sidebar .side-menu li .link.logout {
  color: var(--danger);
}

.content {
  position: relative;
  width: calc(100% - 230px);
  left: 230px;
  transition: all 0.3s ease;
}

.sidebar.close ~ .content {
  width: calc(100% - 60px);
  left: 60px;
}

.content nav {
  height: 56px;
  background: var(--light);
  padding: 0 24px 0 0;
  display: flex;
  align-items: center;
  grid-gap: 24px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
}

.content nav::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: -40px;
  left: 0;
  border-radius: 50%;
  box-shadow: -20px -20px 0 var(--light);
}

.content nav a {
  color: var(--dark);
}

.content nav .bx.bx-menu {
  cursor: pointer;
  color: var(--dark);
}

.content nav form {
  max-width: 400px;
  width: 100%;
  margin-right: auto;
}

.ref-id{
  font-size: 15px;
}

.content nav form .form-input {
  display: flex;
  align-items: center;
  height: 36px;
}

.content nav form .form-input input {
  flex-grow: 1;
  padding: 0 16px;
  height: 100%;
  border: none;
  background: var(--grey);
  border-radius: 36px 0 0 36px;
  outline: none;
  width: 100%;
  color: var(--dark);
}

.content nav form .form-input button {
  width: 80px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary);
  color: var(--light);
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 0 36px 36px 0;
  cursor: pointer;
}

.content nav .notif , .settings {
  font-size: 20px;
  position: relative;
}

.content nav .notif .count {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 20px;
  height: 20px;
  background: var(--danger);
  border-radius: 50%;
  color: var(--light);
  border: 2px solid var(--light);
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content nav .profile {
  background-color: #eee;
  border-radius: 50%;
  padding: 7px;
}
.content nav .profile img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 50%;
}

.content nav .theme-toggle {
  display: block;
  min-width: 50px;
  height: 25px;
  background: var(--grey);
  cursor: pointer;
  position: relative;
  border-radius: 25px;
}

.content nav .theme-toggle::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  width: calc(25px - 4px);
  background: var(--primary);
  border-radius: 50%;
  transition: all 0.3s ease;
}

.content nav #theme-toggle:checked + .theme-toggle::before {
  left: calc(100% - (25px - 4px) - 2px);
}

.content main {
  width: 100%;
  padding: 10px 15px;
  height: 90vh;
}

@media screen and (max-width: 768px) {
  .sidebar {
    width: 200px;
  }

  .content {
    width: calc(100% - 60px);
    left: 200px;
  }
  .ref-id{
    font-size: 10px;
  }
}

@media screen and (max-width: 576px) {
  .content nav form .form-input input {
    display: none;
  }

  .content nav form .form-input button {
    width: auto;
    height: auto;
    background: transparent;
    color: var(--dark);
    border-radius: none;
  }

  .content nav form.show .form-input input {
    display: block;
    width: 100%;
  }

  .content nav form.show .form-input button {
    width: 36px;
    height: 100%;
    color: var(--light);
    background: var(--danger);
    border-radius: 0 36px 36px 0;
  }

  .content nav form.show ~ .notif, 
  .content nav form.show ~ .profile ,  .settings {
    display: none;
  }
}



/* modal --------------------- */
.custom-sidebar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80vh;
}

.custom-sidebar .all-para{
width: 100%;
}

.sidebar-Modal {
  background-color: var(--light);
  width: 30%;
  height: 80vh;
}

.scrollable-list {
  max-height: 100%;
  overflow-y: hidden; /* Hide scrollbar by default */
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.scrollable-list ul {
  width: 90%;
  padding: 0; 
  margin: 0;
  list-style: none; 
}

.scrollable-list ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}

.scrollable-list ul li .link {
  display: flex;
  text-decoration: underline;
  justify-content: center;
  align-items: center;
  background-color: var(--grey);
  height: 35px;
  border-radius: 5px;
  width: 90%;
  text-align: center;
  color: black;
  font-weight: 600;
}

.scrollable-list ul li .link:hover {
  color: green; 
}

/* .scrollable-list ul li .active-link {
  color: green; 
} */

.custom-sidebar .main {
  width: 70%;
  height: 80vh;
  color: black;
}

/* Show scrollbar on hover */
.sidebar-Modal:hover .scrollable-list {
  overflow-y: auto; 
}

.scrollable-list::-webkit-scrollbar {
  width: 2px; 
}

.scrollable-list::-webkit-scrollbar-track {
  background: #ffffff; 
  border-radius: 10px; 
}

.scrollable-list::-webkit-scrollbar-thumb {
  background-color: #1976d2; 
  border-radius: 10px; 
  border: 2px solid #000000; 
}

.scrollable-list::-webkit-scrollbar-thumb:hover {
  background-color: #1565c0; 
}


.scrollable-list {
  scroll-behavior: smooth;
}


@media (max-width: 768px) {
  .sidebar-Modal {
    width: 100%;
  }

  .custom-sidebar .main {
    display: none;
  }
}



