.FormationD{
    /* background-color: aqua; */
    display: flex;
    flex-direction: row;

}

.FormationD .titre{
    margin-right: 20px;
}

.radios{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.radio_label{
    display: flex;
    flex-direction: row;
    margin-right: 20px;
}

.radio_label   input{
    margin-left: 10px;
}


