
.card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
 }  

 .table-header .fa-plus {
  transition: transform 0.3s ease-in-out;
  color: rgb(154, 191, 255);
}

.table-header .fa-plus:hover {
  transform: rotate(90deg);
  color: rgb(154, 191, 255);
}

.table-header h5{
  margin-top: 10px;
}

.tables-homes {

  height: 40vh;
  overflow-y: auto;
}

.table-bordered {
  border: 1px solid #dee2e6 !important; 
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6 !important; 
}



.custom-modal-header .btn-close {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  transition: transform 0.3s ease-in-out;
}
.custom-modal-header .btn-close:hover {
  background-color: rgb(246, 246, 246);
  transform: rotate(90deg);
} 

.card-home {
  position: relative !important; 

}

.card-home:hover {
  transform: translateY(-100px) scale(2.05) !important; 
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2) !important; 
}

.card-1 {
  background: linear-gradient(135deg, #a0d3e8, #03509d); 
  box-shadow: 0 4px 20px rgba(30, 144, 255, 0.3);
}

.card-2 {
  background: linear-gradient(135deg, #d5ffac, #3a6f06); 
  box-shadow: 0 4px 20px rgba(30, 144, 255, 0.3);
}

.card-3 {
  background: linear-gradient(135deg, #ff9aeb, #b40090); 
  box-shadow: 0 4px 20px rgba(30, 144, 255, 0.3);
}

.card-4 {
  background: linear-gradient(135deg, #a3f3ff, #037889); 
  box-shadow: 0 4px 20px rgba(30, 144, 255, 0.3);
}



.All-tables{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.table-home {
  height: 50vh;
  border-radius: 20px;
  margin-left: 5px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2), 0 5px 15px rgba(0, 0, 0, 0.1); 
  padding: 10px;
}


@media (max-width: 600px) {
  .All-tables{
    display: flex;
    flex-direction: column;
  }

  .table-home{
    margin-bottom: 10px;
  }

}








