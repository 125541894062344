@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600&display=swap");

* {
  font-family: "Outfit", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --main-color: #5E61FF;
  --dark-color: #a71555;
  --step-color: #ced7e0;
  --step-size: 32px;
  --steps: 4;
  --progress-width: calc(((var(--steps) - 1) / var(--steps)) * 100%);
}

.modalform {
  margin-top: 30px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e2e2f6;
  color: #333;
}

.modalformlogin {
  margin-top: 40px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e2e2f6;
  color: #333;
}

h1 {
  text-align: center;
  margin-bottom: 16px;
}

h3 {
  margin-bottom: 16px;
}

label {
  display: inline-block;
  margin-bottom: 4px;
}

input,
textarea {
  outline-color: var(--main-color);
  border: 1px solid rgb(14, 27, 107);
  padding: 8px 16px;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  text-align: start;
}

input[type="tel"] {
  direction: inherit;
}

textarea {
  resize: none;
}

button {
  cursor: pointer;
  border: none;
  border-radius: 16px;
  padding: 8px 16px;
  background-color: var(--main-color);
  color: #fff;
  font-size: 14px;
  transition: 0.3s;
}

button:active,
button:hover {
  background-color: var(--dark-color);
}

button:disabled {
  opacity: 0.8;
}

button:focus {
  outline-color: #333;
}

button[type="submit"] {
  background-color: #333;
}

button[type="submit"]:hover,
button[type="submit"]:active {
  background-color: #030202;
}


.form-wizard {
  background-color: #fff;
  padding: 32px;
  border-radius: 8px;
  width: min(550px, 80%);
  box-shadow: 0 4px 16px rgba(167, 21, 84, 0.1);
}

.progress-container {
  position: relative;
}

.progress-container::before {
  content: "";
  height: 4px;
  width: var(--progress-width);
  background-color: var(--step-color);
  position: absolute;
  top: calc((var(--step-size)) / 2);
  left: 50%;
  translate: -50% -50%;
}

.progress-container .progress {
  height: 4px;
  width: var(--progress-width);
  background-color: var(--main-color);
  position: absolute;
  top: calc((var(--step-size)) / 2);
  left: 50%;
  translate: -50% -50%;
  z-index: 2;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.3s;
}

html[dir="rtl"] .progress-container .progress {
  transform-origin: right;
}

.progress-container ol {
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  counter-reset: step-number;
  position: relative;
  z-index: 3;
}

.progress-container li {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--step-color);
  transition: 0.3s linear 0.3s;
  text-align: center;
}

.progress-container li::before {
  counter-increment: step-number;
  content: counter(step-number);
  background-color: var(--step-color);
  width: var(--step-size);
  height: var(--step-size);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  transition: 0.3s linear 0.3s;
}

.progress-container li:where(.done, .current) {
  color: #333;
  font-weight: 500;
}

.progress-container li.done::before {
  background-color: var(--main-color);
}

.progress-container li.current::before {
  background-color: var(--main-color);
  box-shadow: 0 0 0 3px rgba(167, 21, 84, 0.25);
}

.steps-container {
  display: flex;
  overflow: hidden;
}

.step {
  flex: 1 0 100%;
  padding: 24px 8px;
  opacity: 0;
  transition: opacity 0.3s;
  height: fit-content;
  display: grid;
  gap: 8px;
}

.step.current {
  opacity: 1;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding-inline: 8px;
}

.controls button {
  flex: 1;
}

.completed {
  text-align: center;
}

.completed h3 {
  margin-bottom: 8px;
}

.completed svg {
  width: 100px;
  height: 100px;
  stroke: yellowgreen;
}

.completed:not([hidden]) ~ * {
  display: none;
}

.error{
  color: #a71555;
  
}
