.containers {
  height: 80vh;
}
.custom-card {
  width: 80%;
  margin-top: 40px;
  background-color: rgb(89, 130, 232) !important;
  color: white !important;
}

.custom-card h5 {
  margin-bottom: 10px;
}
.custom-card p {
  margin-top: 10px;
}
.custom-card button {
  margin-top: 15px;
}

/* container 2  */

.form-group {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.form-group > label:first-of-type {
  width: 80%;
  font-weight: bold;
  font-size: 16px;
  color: #333;
}
.radio-group {
  display: flex;
  flex-direction: row;
  width: 10%;
}

.toute {
  margin-bottom: 5px;
}
.toute input {
  height: 30px;
}
