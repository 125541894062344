.drop {
  display: flex !important;
  align-items: center;
}


.all-para {
  display: flex;
  flex-direction: row;
}

.sidebar-Modal {
    width: 25%;
    background-color: rgb(228, 228, 228);
}
.sidebar-Modal ul {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.sidebar-Modal ul li{
    width: 100%;
    margin-top: 20px;
}

.sidebar-Modal ul li Button{
    background-color: blue;
    width: 90%;
}

.main-modal {
    width: 75%;
}

@media (max-width: 576px) {

    .all-para {
        display: flex;
        flex-direction: column;
      }
    .sidebar-Modal{
        width: 100%;
    }

    .main-modal {
        width: 100%;
    }
  }

