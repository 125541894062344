.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.custom-modal .close-btn {
  position: absolute;
  top: 10px; 
  right: 15px; 
  background: rgb(238, 238, 238); 
  border: 1px solid rgb(200, 200, 200);
  border-radius: 5px; 
  color: rgb(53, 24, 24); 
  font-size: 20px;
  cursor: pointer;
  padding: 5px 10px; 
  transition: background 0.3s; 
}

.custom-modal .close-btn:hover {
  background: rgb(200, 200, 200); 
}

.custom-modal .card {
  background-color: white;
}

.modal-profile .nom ,  .prenom{
  color: black;
}