.groupSeance{
    display: flex;
    overflow-x: auto;
    scrollbar-width: thin; 
    width: 100%;
    justify-content: space-around;
}
.seance{
    background-color: rgb(0, 0, 0);
    margin-right: 5px;
    margin-bottom: 5px;
    color: white;
    cursor: pointer;
}
.seance.active{
    background-color: rgb(30, 0, 255);
}

.seance .date{
    background-color: rgb(30, 0, 255);
    width: auto;
    padding: 3px;
    font-family: initial;
    font-size: 13px;
    text-align: center;
}
.seance .day{
    width: auto;
    padding: 3px;
    font-family: initial;
    font-size: 13px;
    text-align: center;
}
.seance .time{
    width: auto;
    padding: 3px;
    font-family: initial;
    font-size: 13px;
    text-align: center;
}

