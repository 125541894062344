.container-form {
  height: 87vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.groupe-form {
  background-color: rgb(7, 7, 173);
  padding: 20px;
  border-radius: 10px;
  width: 50%;
}

.groupe-form h2{
    text-align: center;
}


.forms{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.groupe-form input{
    margin-top: 20px;
    width: 80%;
}
.groupe-form button{
    margin-top: 20px;
    margin-bottom: 15px;
    width: 80%;
}


