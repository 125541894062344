.table-responsive-etud {
    overflow-x: auto; 
    overflow-y: auto; 
    -webkit-overflow-scrolling: touch; 
    max-height: 400px; 
    height: 65vh;
  }



.table-responsive-etud::-webkit-scrollbar {
    width: 3px; 
    background-color: black;
  }
  
  .table-responsive-etud::-webkit-scrollbar-thumb {
    background-color: darkgrey; 
    border-radius: 50px; 
  }
  
  .table-responsive-etud::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1); 
  }
  

  .table-responsive-etud {
    scrollbar-width: thin; 
    scrollbar-color: darkgrey rgba(0, 0, 0, 0.1);
  }

  