
.all-selection{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.all-selection .select-name{
    width: 20%;
}

.formation-group{
    width: 20%;
}

.group-details{
    display: flex;
    width: 40%;
}

.select-group{
    width: 50%;
}

.nouvelle-seance{

    width: 15%;
}
.nouvelle-seance Button{

    width: 100%;
}




.all-selection .name-individual{
    width: 20%;
}

.details-individual{
    width: 20%;
}
.details-individual Button{
    width: 80%;
}







@media screen and (max-width: 600px) {

    .all-selection{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction:column ;
        height: 60px;
        overflow-y: auto; 
    }

    .all-selection .select-name{
        width: 100%;
        margin-bottom: 5px;
    }

    .formation-group{
        width: 100%;
        margin-bottom: 5px;

    }
    .group-details{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 5px;

    }

    .select-group{
        width: 100%;
        margin-bottom: 5px;

    }

    .nouvelle-seance{

        width: 100%;
    }
    .nouvelle-seance Button{

        width: 100%;
    }


.all-selection .name-individual{

    width: 100%;
    margin-bottom: 5px;
}

.details-individual{

    width: 100%;
    margin-bottom: 5px;
}
.details-individual Button{

    width: 100%;
}


}

