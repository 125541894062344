.link-login {
    width: 100%;
    margin-top: 20px;
    padding-inline: 13px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .link-login p {
    margin: auto; 
  }


  @media (max-width: 600px) {

    .link-login {
      width: 100%;
      margin-top: 20px;
      padding-inline: 13px;
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .link-login p {
      margin: auto; 
      margin-bottom: 10px;
    }
  }