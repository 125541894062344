.table-responsive {
    overflow-x: auto; 
    overflow-y: auto; 
    -webkit-overflow-scrolling: touch; 
    max-height: 400px; 
    height: 65vh;
  }



.table-responsive::-webkit-scrollbar {
    width: 3px; 
    background-color: black;
  }
  
  .table-responsive::-webkit-scrollbar-thumb {
    background-color: darkgrey; 
    border-radius: 50px; 
  }
  
  .table-responsive::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1); 
  }
  

  .table-responsive {
    scrollbar-width: thin; 
    scrollbar-color: darkgrey rgba(0, 0, 0, 0.1);
  }



  .custom-modal .modal-content {
    background-color: black;
    color: white; 
  }
