.page1 {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.group-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.group-btn div {
  cursor: pointer;
}
.btn-choix {
  background-color: rgb(80, 118, 255);
  margin-bottom: 20px;
  padding: 13px;
  width: 60%;
  text-align: center;
  color: white;
  border-radius: 7px;
}

.active,
.btn-choix:hover {
  background-color: rgb(0, 16, 72);
}

.page2 {
  display: flex;
  flex-direction: column;
}

.page2 .search {
  margin-bottom: 15px;
}

.search .titre {
  font-size: 15px;
  font-weight: bold;
}

.ticket {
  position: relative;
  background: rgb(4, 29, 141);
  color: white;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  padding: 1em 2em;
}

.tickets .all-teaching-modal {
  width: 100%;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.ticket:before,
.ticket:after {
  height: 90px;
  width: 45px;
  content: "";
  position: absolute;
  top: 0.9em;
  top: calc(0.9em - 1px);
  height: 1.2em;
  width: 0.6em;
}

.ticket:before {
  left: -2px;
  border-radius: 0 1em 1em 0;
  border-left-color: white;
  background-color: white;
}

.ticket:after {
  right: -2px;
  border-radius: 1em 0 0 1em;
  border-right-color: white;
  background-color: white;
}



.select-z-index {
  z-index: 1000; 
  position: relative; 
}

.groupSeance {
  display: flex;
  overflow-x: auto;
  scrollbar-width: thin;
  width: 100%;
  justify-content: space-around;
}
.seance {
  background-color: rgb(0, 0, 0);
  margin-right: 5px;
  margin-bottom: 5px;
  color: white;
  cursor: pointer;
}
.seance.active {
  background-color: rgb(30, 0, 255);
}

.seance .date {
  background-color: rgb(30, 0, 255);
  width: auto;
  padding: 3px;
  font-family: initial;
  font-size: 13px;
  text-align: center;
}
.seance .day {
  width: auto;
  padding: 3px;
  font-family: initial;
  font-size: 13px;
  text-align: center;
}
.seance .time {
  width: auto;
  padding: 3px;
  font-family: initial;
  font-size: 13px;
  text-align: center;
}


.selectedteaching {
  background-color: rgb(49, 89, 102);
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  margin-left: 2px; 
  margin-right: 2px; 
}

.teaching-choice {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  margin-left: 2px; 
  margin-right: 2px; 
}

.not-selected {
  background-color: #a1a1a1;
  color: black;
  margin-left: 2px; 
  margin-right: 2px; 
}

.price-modal {
  background-color: rgb(254, 0, 0);
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: white;
}
.teaching-modal {

  text-align: center;
  margin-top: 5px;
}

