.container-password {
  height: 87vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.container-contenu {
  background-color: rgb(7, 7, 173);
  width: 60%;
  padding: 30px;
}
.request-password {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.btn-capatcha{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-between;

}

.btn-contenu button{
    margin-top: 20px;
}

.btn-contenu button:hover{
    background-color: yellowgreen;
    color: white;
}


@media (max-width: 768px) {

    .btn-capatcha{
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        justify-content: space-between;
    }

    .recaptcha{
        width: 2px;
    }
    
}
