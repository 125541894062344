.ticket {
  position: relative;
  background: rgb(12, 38, 151);
  color: white;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  height: 120px;
  margin-left: 50px;
}
.tickets {
    overflow-x: auto; 
    -webkit-overflow-scrolling: touch; 
    scrollbar-width: thin;
}
.tickets ul {
  width: 100%;
  justify-content: space-around;
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: row; 
}

.ticket:before,
.ticket:after {
  height: 90px;
  content: "";
  position: absolute;
  top: 0.9em;
  top: calc(0.9em - 1px);
  width: 0.6em;
}

.ticket:before {
  left: -2px;
  border-radius: 0 1em 1em 0;
  border-left-color: white;
  background-color: white;
}

.ticket:after {
  right: -2px;
  border-radius: 1em 0 0 1em;
  border-right-color: white;
  background-color: white;
}
.ticket.active{
  background: rgb(3, 13, 57);

}
.ticket:hover{
  background: rgb(3, 13, 57);
}
.price {
    background-color: rgb(255, 0, 0);
    color: white;
    
}

.numbers p{
    color: white;

}

@media (max-width: 600px) {
    .tickets ul{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .tickets ul li{
        margin-bottom: 10px;
        
    }
}